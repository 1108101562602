import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import styled from "styled-components";

export const Wrapper = styled.div`
	margin: 80px auto;
	max-width: 600px;
	display: flex;
	flex-direction: column;
	align-items: center;
	${({ isDarkBg }) =>
		isDarkBg &&
		`
    .accordion-header-text {
      color: var(--theme-light-font-color)
    }
  `}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		margin: 40px auto;
		padding-inline: 24px;
	}

	.accordion-header-text {
		margin-bottom: 2.5rem;
	}
	.show-link {
		margin-top: 1.5rem;
		border: none;
		background: transparent;
		box-shadow: none;
		color: ${({ showLinkColor }) =>
			showLinkColor ? showLinkColor : "var(--secondary-purple-300)"};
		& p {
			color: ${({ showLinkColor }) =>
				showLinkColor ? showLinkColor : "var(--secondary-purple-300)"};
		}
	}

	.accordion-heading {
		padding-inline: 0;
	}
	.accordion-content {
		padding-inline: 0;
		padding-top: 0;
	}
`;
