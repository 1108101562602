import React, { useState } from "react";
import { Accordion, Text, Link } from "@website-builder/ui/shared/elements";
import { Wrapper } from "./styles";
import { formatColor } from "@website-builder/utilities/utils/DesignUtils.js";

const RedesignFAQ = ({
	header,
	defaultShowCount,
	accordionItems,
	showLinkColor,
	isDarkBg = false,
}) => {
	const [itemsToShow, setItemsToShow] = useState(
		defaultShowCount && defaultShowCount < accordionItems.length
			? accordionItems.slice(0, defaultShowCount)
			: accordionItems,
	);
	const [showingAllItems, setShowingAllItems] = useState(false);
	const [icon, setIcon] = useState("caretDown");

	const onViewLess = () => {
		setItemsToShow(accordionItems.slice(0, defaultShowCount));
		setShowingAllItems(false);
		setIcon("caretDown");
	};
	const onViewMore = () => {
		setItemsToShow(accordionItems);
		setShowingAllItems(true);
		setIcon("caretUp");
	};

	return (
		<Wrapper
			className="redesign-faq outer-margin-wrap"
			showLinkColor={formatColor(showLinkColor)}
			isDarkBg={isDarkBg}
		>
			<Text variant="headline_3" tag="h2" className="accordion-header-text">
				{header}
			</Text>
			<Accordion
				accordionItems={accordionItems}
				htmlTagForItemHeading="h3"
				icon="plusMinus"
				showItemTopSeparator={false}
				accordionHeadingClassName={"accordion-heading"}
				accordionContentClassName={"accordion-content"}
				richTextContent
				showItems={showingAllItems ? accordionItems?.length : defaultShowCount}
				isDarkBg={isDarkBg}
			/>
			{defaultShowCount && defaultShowCount < accordionItems.length ? (
				<Link
					className="show-link"
					data-accordion-state={`view-${showingAllItems ? "less" : "more"}`}
					iconConfig={{ iconName: icon, stroke: formatColor(showLinkColor) }}
					iconRight
					onClick={showingAllItems ? onViewLess : onViewMore}
					as="button"
				>
					View {showingAllItems ? "less" : "more questions"}
				</Link>
			) : null}
		</Wrapper>
	);
};

export default RedesignFAQ;
